import React, { useState, useEffect } from 'react';
import { map, sortBy, sum, sumBy } from 'lodash';
import NumberInput from './NumberInput';

const cleanNum = (num) => {
    return num == null || num.toString() === 'NaN' ? 0 : Number(num);
}

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const cash = (num) => USDollar.format(cleanNum(num));
const pct = (num) => cleanNum(num).toFixed(2) + "%";

const totalAchievedGoal = (programOutcomes) => { return sum(map(programOutcomes, o => o.achievedGoal == null ? 0 : Number(o.achievedGoal))) };
const totalValueOfOutcomes = (programOutcomes) => {
    return sum(map(programOutcomes, o => (o.achievedGoal == null || o.value == null)
        ? 0 : Number(o.achievedGoal) * Number(o.value)))
};

const programSROI = (programOutcomes, totalCost) => totalValueOfOutcomes(programOutcomes) / totalCost * 100;
const outcomeSROI = (o, totalCost, programOutcomes) => (Number(o.value) * o.achievedGoal) / (totalCost / programOutcomes.length) * 100;
const serviceSROI = (ss) => ss.OutcomeValue * ss.AchievedGoal / ss.TotalBudget * 100;

function Program(props) {
    const services = sortBy(props.services, 'Service');
    const outcomes = sortBy(props.outcomes, 'Outcome');
    const program = props.program;
    const updateCsvContent = props.updateCsvContent;

    const [selectedServices, setSelectedServices] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [programOutcomes, setProgramOutcomes] = useState([]);
    const [participants, setParticipants] = useState(0);
    const [showServices, setShowServices] = useState(false);

    const handleProgramChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'totalCost':
                setTotalCost(Number(value));
                break;
            case 'participants':
                setParticipants(Number(value));
                break;
            default:
                return;
        }
    }

    const handleOutcomeChange = (e, index) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'outcome':
                setProgramOutcomes([...programOutcomes.slice(0, index)
                    , { ...programOutcomes[index], outcome: value, value: getOutcomeValue(value) }
                    , ...programOutcomes.slice(index + 1)]);
                break;

            case 'achievedGoal':
                setProgramOutcomes([...programOutcomes.slice(0, index)
                    , { ...programOutcomes[index], achievedGoal: value }
                    , ...programOutcomes.slice(index + 1)]);
                break;

            case 'value':
                setProgramOutcomes([...programOutcomes.slice(0, index)
                    , { ...programOutcomes[index], value: value }
                    , ...programOutcomes.slice(index + 1)]);
                break;

            default:
                return;
        }
    }

    const addOutcome = () => {
        const newProgramOutcomes = [...programOutcomes, {}];
        setProgramOutcomes(newProgramOutcomes);
    }

    const removeOutcome = (index) => {
        setProgramOutcomes([...programOutcomes.slice(0, index), ...programOutcomes.slice(index + 1)]);
    }

    const selectService = e => {
        const selectedService = e.target.innerText;
        if (selectedService === '')
            return;

        const service = services.find(r => r.Service === selectedService);
        const thisRow = {
            ...service,
            Participants: 0,
            AchievedGoal: 0,
            TotalBudget: 0
        };

        //add it to the collection, or if it's already selected, remove it
        const newSelectedServices = selectedServices.map(s => s.Service).includes(service.Service) ?
            selectedServices.filter(s => s.Service !== service.Service) :
            [...selectedServices, thisRow];

        setSelectedServices(newSelectedServices);
    }

    const handleServiceChange = (ss, field, event) => {
        const value = event.target.value;
        const thisSS = selectedServices.find(s => s.Program === ss.Program && s.Service === ss.Service);

        const outcomeValue = field === 'Outcome' ? getOutcomeValue(value) : field === 'OutcomeValue' ? value : thisSS.OutcomeValue;
        const newSS = {
            ...thisSS,
            [field]: value,
            OutcomeValue: outcomeValue,
        };

        const index = selectedServices.indexOf(thisSS);
        const newSelectedServices = [
            ...selectedServices.slice(0, index),
            newSS,
            ...selectedServices.slice(index + 1)];
        setSelectedServices(newSelectedServices);
    }

    const getOutcomeValue = (so) => so ? outcomes.find(o => so === o.Outcome).Value : 0;

    const totalBudget = () => selectedServices.length > 0 ? sumBy(selectedServices, s => cleanNum(s.TotalBudget)).toFixed(2) : totalCost.toFixed(2);

    useEffect(() => {
        const updateCsvRows = () => {
            const progRow = {
                program: program,
                service: null,
                budget: cash(totalCost),
                enrolled: participants,
                cost: cash(totalCost / participants),
                outcome: null,
                achieved: null,
                success: null,
                outcomeCost: null,
                outcomeValue: null,
                outcomeTotalValue: null,
                sroi: null
            };

            const progOutcomeRows = programOutcomes.map(o => {
                return {
                    program: null,
                    service: null,
                    budget: null,
                    enrolled: null,
                    cost: null,
                    outcome: o.outcome,
                    achieved: o.achievedGoal,
                    success: pct(o.achievedGoal / participants * 100),
                    outcomeCost: cash(totalCost / participants * o.achievedGoal),
                    outcomeValue: cash(o.value),
                    outcomeTotalValue: cash(o.value * o.achievedGoal),
                    sroi: pct(outcomeSROI(o, totalCost, programOutcomes))
                }
            });

            const progOutcomeTotalRow = {
                program: null,
                service: null,
                budget: null,
                enrolled: null,
                cost: null,
                outcome: 'Total',
                achieved: totalAchievedGoal(programOutcomes),
                success: pct(totalAchievedGoal(programOutcomes) / participants * 100),
                outcomeCost: cash(totalCost / participants * totalAchievedGoal(programOutcomes)),
                outcomeValue: null,
                outcomeTotalValue: cash(totalValueOfOutcomes(programOutcomes)),
                sroi: pct(programSROI(programOutcomes, totalCost))
            }

            const serviceRows = selectedServices.map(ss => {
                return {
                    program: null,
                    service: ss.Service,
                    budget: cash(ss.TotalBudget),
                    enrolled: ss.Participants,
                    cost: cash(ss.TotalBudget / ss.Participants),
                    outcome: ss.Outcome,
                    achieved: ss.AchievedGoal,
                    success: null,
                    outcomeCost: cash(ss.TotalBudget / ss.Participants * ss.AchievedGoal),
                    outcomeValue: cash(ss.OutcomeValue),
                    outcomeTotalValue: cash(ss.OutcomeValue * ss.AchievedGoal),
                    sroi: pct(serviceSROI(ss))
                }
            });

            updateCsvContent([progRow, ...progOutcomeRows, progOutcomeTotalRow, ...serviceRows]);
        };

        updateCsvRows();
    }, [participants, program, programOutcomes, selectedServices, totalCost]);

    return (
        <>
            <div className="program">
                <div className="row header">
                    <span className="title">{program}</span>
                    <span className={`sroi ${programSROI(programOutcomes, totalCost) > 0 ? 'positive' : 'negative'}`}>
                        <span className="sroi-label">Program SROI:</span>
                        {pct(programSROI(programOutcomes, totalCost))}
                    </span>
                </div>
                <div className="row">
                    <div className={`input w-25 focus ${totalBudget() !== totalCost.toFixed(2) ? 'error' : null}`}>
                        <label>
                            <span>Budget</span>
                            <NumberInput name='totalCost' value={totalCost} onChange={handleProgramChange}></NumberInput>
                        </label>
                    </div>
                    <div className="input w-25 focus">
                        <label>
                            <span>Clients Enrolled</span>
                            <NumberInput name='participants' value={participants} onChange={handleProgramChange}></NumberInput>
                        </label>
                    </div>
                    <span className="calculated">Unit cost per client: <span>{cash(totalCost / participants)}</span></span>
                </div>

                {totalBudget() !== totalCost.toFixed(2) && (
                    <div className="row">
                        <span className="error-msg">The program budget must equal the sum of all added service(s) budget(s).</span>
                    </div>
                )}

                {programOutcomes.map((o, index) => (
                    <div key={`${o.outcome}-${index}`}>
                        <div className="row">
                            <button className="delete" type="button" onClick={() => removeOutcome(index)}></button>
                            <div className="input w-33 focus">
                                <label>
                                    <span>Outcome</span>
                                    <select name='outcome' value={o.outcome} onChange={(e) => handleOutcomeChange(e, index)}>
                                        <option value=''>Select...</option>
                                        {map(outcomes, s => (<option key={s.Outcome} value={s.Outcome}>{s.Outcome}</option>))}
                                    </select>
                                </label>
                            </div>
                            <div className="input w-33 focus">
                                <label>
                                    <span>Clients Achieved Outcome</span>
                                    <NumberInput name='achievedGoal' value={Number(o.achievedGoal).toString()} onChange={(e) => handleOutcomeChange(e, index)}></NumberInput>
                                </label>
                            </div>
                            <div className="input w-33 focus">
                                <label>
                                    <span>Outcome Value</span>
                                    <NumberInput name='value' value={Number(o.value).toString()} onChange={(e) => handleOutcomeChange(e, index)}></NumberInput>
                                </label>
                            </div>
                        </div>
                        {/* {programOutcomes.length > 1 && (
                            <div className="row">
                                <span className={`sroi outcome-sroi ${outcomeSROI(o, totalCost, programOutcomes) > 0 ? 'positive' : 'negative'}`}>
                                    <span className="sroi-label">Outcome SROI:</span>
                                    <span>{pct(outcomeSROI(o, totalCost, programOutcomes))}</span>
                                </span>
                            </div>
                        )} */}
                    </div>
                ))}

                <div className="row"><button onClick={addOutcome} className="add">Add Outcome</button></div>
                <div className="row">
                    <span className="calculated">Client Success Rate: <span>{pct(totalAchievedGoal(programOutcomes) / participants * 100)}</span></span>
                    {/* <span className="calculated">Cost of Outcomes: <span>{cash(totalCost / participants * totalAchievedGoal(programOutcomes))}</span></span> */}
                    {/* <span className="calculated">Value of Outcomes: <span>{cash(totalOutcomeValue())}</span></span> */}
                    <span className="calculated">Total Value of Outcomes: <span>{cash(totalValueOfOutcomes(programOutcomes))}</span></span>
                </div>

                <button className={`add add-service ${showServices ? 'adding' : ''}`} type="button" onClick={() => setShowServices(!showServices)}>{`${showServices ? 'Adding' : 'Add'} Services`}</button>
            </div>

            {showServices && (
                <ul className="services">
                    {map(services, s =>
                        <li key={s.Service}>
                            <button key={s.Service} className={selectedServices.map(s => s.Service).includes(s.Service) ? 'selected' : null} onClick={selectService}><span>{s.Service}</span></button>
                        </li>
                    )}
                </ul>
            )}

            {selectedServices.map(ss => (
                <div key={`${ss.Program}-${ss.Service}`} className="service">
                    <div className="row header">
                        <span className="title">{ss.Service}</span>
                        <span className={`sroi ${serviceSROI(ss) > 0 ? 'positive' : 'negative'}`}>
                            <span className="sroi-label">Service SROI:</span>
                            {pct(serviceSROI(ss))}
                        </span>
                    </div>
                    <div className="row">
                        <div className="input w-25 focus">
                            <label>
                                <span>Budget</span>
                                <NumberInput value={ss.TotalBudget} onChange={(event) => handleServiceChange(ss, 'TotalBudget', event)}></NumberInput>
                            </label>
                        </div>
                        <div className="input w-25 focus">
                            <label>
                                <span>Clients Enrolled</span>
                                <NumberInput value={ss.Participants} onChange={(event) => handleServiceChange(ss, 'Participants', event)}></NumberInput>
                            </label>
                        </div>
                        <span className="calculated">Unit cost per client: <span>{cash(ss.TotalBudget / ss.Participants)}</span></span>
                    </div>
                    <div className="row">
                        <div className="input w-33 focus">
                            <label>
                                <span>Outcome</span>
                                <select value={ss.Outcome} onChange={(event) => handleServiceChange(ss, 'Outcome', event)}>
                                    <option value=''>Select...</option>
                                    {map(outcomes, s => (<option key={s.Outcome} value={s.Outcome}>{s.Outcome}</option>))}
                                </select>
                            </label>
                        </div>
                        <div className="input w-33 focus">
                            <label>
                                <span>Clients Achieved Outcome</span>
                                <NumberInput max={ss.Participants} value={Number(ss.AchievedGoal).toString()} onChange={(event) => handleServiceChange(ss, 'AchievedGoal', event)}>
                                </NumberInput>
                            </label>
                        </div>
                        <div className="input w-33 focus">
                            <label>
                                <span>Outcome Value</span>
                                <NumberInput value={ss.OutcomeValue} onChange={(event) => handleServiceChange(ss, 'OutcomeValue', event)} className="inline-input"></NumberInput>
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        {/* <span className="calculated">Performance Rate of Success: <span>{pct(ss.AchievedGoal / ss.Participants * 100)}</span></span> */}
                        {/* <span className="calculated">Cost of Outcomes: <span>{cash(ss.TotalBudget / ss.Participants * ss.AchievedGoal)}</span></span> */}
                        <span className="calculated">Client Success Rate: <span>{pct(ss.AchievedGoal / ss.Participants * 100)}</span></span>
                        <span className="calculated">Total Value of Outcomes: <span>{cash(ss.OutcomeValue * ss.AchievedGoal)}</span></span>
                    </div>
                    <button className="delete" type="button" onClick={() => selectService({ target: { innerText: ss.Service } })}></button>
                </div >
            ))
            }
        </>
    )
}

export default Program;
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { filter, map, uniq } from 'lodash';
import CsvDownloader from 'react-csv-downloader';

import Program from './Program.js';

const getData = async (path) => {
  const response = await fetch(path)
  const reader = response.body.getReader()
  const result = await reader.read() // raw array
  const decoder = new TextDecoder('utf-8')
  const csv = decoder.decode(result.value) // the csv text
  const results = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
  const rows = results.data // array of objects
  return rows.filter(r => r.Program !== '');
}

function App() {
  const [programs, setPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [services, setServices] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem('darkMode') === 'true' ? true : false;
  });

  const [csvContent, setCsvContent] = useState(null);

  const csvColumns = [
    { id: 'program', displayName: 'Program' },
    { id: 'service', displayName: 'Service' },
    { id: 'budget', displayName: 'Budget' },
    { id: 'enrolled', displayName: 'Clients Enrolled' },
    { id: 'cost', displayName: 'Unit Cost per Client' },
    { id: 'outcome', displayName: 'Outcome' },
    { id: 'achieved', displayName: 'Clients Achieved Outcome' },
    { id: 'success', displayName: 'Performance Rate of Success' },
    { id: 'outcomeCost', displayName: 'Cost of Outcomes' },
    { id: 'outcomeValue', displayName: 'Value of Outcomes' },
    { id: 'outcomeTotalValue', displayName: 'Total Value of Outcomes' },
    { id: 'sroi', displayName: 'SROI' }
  ];

  useEffect(() => {
    if (programs.length === 0)
      populateControl();

    localStorage.setItem('darkMode', darkMode);
  }, [darkMode, programs]);

  const populateControl = async () => {
    const services = await getData('/data/services.csv');
    const outcomes = await getData('/data/outcomes.csv');
    const programs = uniq(services.map(r => r.Program));

    setServices(services);
    setPrograms(programs);
    setOutcomes(outcomes);
  }

  const updateCsvContent = (data) => setCsvContent(data);

  const selectProgram = (program) => () => {
    if (selectedPrograms.includes(program)) {
      const thisP = selectedPrograms.find(p => p === program);
      const index = selectedPrograms.indexOf(thisP);
      const newSelectedPrograms = [
        ...selectedPrograms.slice(0, index), ...selectedPrograms.slice(index + 1)
      ];
      setSelectedPrograms(newSelectedPrograms);
    }
    else {
      const newSelectedPrograms = [program, ...selectedPrograms];
      setSelectedPrograms(newSelectedPrograms);
    }
  };

  const getServices = (program) => {
    return filter(services, s => s.Program === program);
  }

  const getOutcomes = (program) => {
    return filter(outcomes, o => o.Program === program);
  }

  return (
    <div className={`app-wrapper ${darkMode ? '' : 'light-mode'}`}>
      <div className="calc-wrapper">
        <div className="calc-padding">
          <button className="theme-toggle" type="button" onClick={() => setDarkMode(!darkMode)}>Dark Mode: {darkMode ? 'On' : 'Off'}</button>
          <h1>SROI Calculator</h1>
          <p className="intro">Choose a program below and complete the necessary fields to see the expected return of investment on each program.</p>
          <ul className="programs">
            {map(filter(programs, p => programs.includes(p)), p =>
              <li key={p}>
                <button type='button' className={selectedPrograms.includes(p) ? 'selected' : null} onClick={selectProgram(p)}><span>{p}</span></button>
              </li>
            )}
          </ul>
        </div>

        {selectedPrograms.length > 0 && (
          <div className="roi-wrapper">
            <div className="calc-padding">
              {map(selectedPrograms, p => (
                <Program key={p} program={p} services={getServices(p)} outcomes={getOutcomes(p)} removeProgram={selectProgram} updateCsvContent={updateCsvContent} />
              ))}
            </div>
          </div>

        )}
      </div>
      <div className="fixed-button help">
        <a href='/SROI Calculator Guide.pdf' target='_blank'><button>User Guide</button></a>
      </div>
      <div className="fixed-button download">
        <CsvDownloader text="Download CSV" wrapColumnChar="&quot;" columns={csvColumns} datas={csvContent} filename='elogic_sroi_tool_export' />
      </div>
    </div>
  );
}

export default App;
